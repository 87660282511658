<template>
  <span class="badge">
    <slot />
  </span>
</template>
<style scoped lang="scss">
.badge {
  --size: 20px;
  --font-size: 12px;
  --color: var(--c-white);
  --background: var(--c-red-100);
  --padding: 2px 6px;
  display: grid;
  place-content: center;
  font-size: var(--font-size);
  font-weight: bold;
  color: var(--color);
  background-color: var(--background);
  min-width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  padding: var(--padding);
}
</style>
